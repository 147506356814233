export const SET = 'SERVER/SET';
export const RESET = 'SERVER/RESET';
export const REQUEST = 'SERVER/REQUEST';

export const iStatus = {
	NEW     : "NEW",
	STARTED : "STARTED",
	CREATING: "CREATING",
	CREATED : "CREATED",
	ERROR   : "ERROR",
	FINISHED: "FINISHED",
};

export interface iAction {
	type: string;
	payload?: string | iServer;
}

export interface iServer {
	id: string
	name: string
	email: string
	provider: string
	service: string
	isPaid?: boolean
	protocol: string
	region: string
	port: string
	maskIp: boolean
	emailSent: boolean
	emailSentCounter: number
	dnsServers: string[];
	serverStatus: string
	createdAt: number;
	updatedAt: number;
	clientConfig: string;
}

export interface iState {
	server?: iServer;
	loading?: boolean;
}

const initialState: iState = {};

export const set = (server: iServer) => ({type: SET, payload: server});
export const reset = () => ({type: RESET});
export const request = (id: string) => ({type: REQUEST, payload: id});

export default (state = initialState, action: iAction) => {
	const {type, payload} = action;
	switch (type) {
		case SET:
			return {...state, ...{server: payload, loading: false}};
		case RESET:
			return initialState;
		case REQUEST:
			return {...state, ...{loading: true}};

		default:
			return state;
	}
};
