export const REQUEST_AUTH = 'DIGITALOCEAN/REQUEST_AUTH';
export const SET_AUTH_TOKEN = 'DIGITALOCEAN/SET_AUTH_TOKEN';
export const SET_REGIONS = 'DIGITALOCEAN/SET_REGIONS';
export const REQUEST_REGIONS = 'DIGITALOCEAN/REQUEST_REGIONS';
export const SET_LOADING_REGIONS = 'DIGITALOCEAN/SET_LOADING_REGIONS';


export interface iAction {
	type: string;
	payload?: string | boolean | iRegion[] | iRegion | iOauthToken;
}

export interface iOauthToken {
	token: string;
	expiresAt: number;
}

export interface iState {
	authToken?: iOauthToken;
	isAuthenticating: boolean;
	isLoadingRegions?: boolean;
	regions: iRegion[];
}

export interface iRegion {
	name: string;
	slug: string;
	available: boolean;
	memory: number;
	vcpus: number;
	price_monthly: number;
	sizes: string[];
	country: string;
}

const initialState: iState = {
	regions         : [],
	isAuthenticating: false
};

export const reqAuth = () => ({type: REQUEST_AUTH});
export const setToken = (token: iOauthToken) => ({type: SET_AUTH_TOKEN, payload: token});
export const requestRegions = () => ({type: REQUEST_REGIONS});
export const setRegions = (regions: iRegion[]) => ({type: SET_REGIONS, payload: regions});
export const setLoadingRegions = (isIt: boolean) => ({type: SET_LOADING_REGIONS, payload: isIt});

export default (state = initialState, action: iAction) => {
	const {type, payload} = action;
	switch (type) {
		case SET_AUTH_TOKEN:
			return {...state, ...{authToken: payload, isAuthenticating: false}};
		case SET_LOADING_REGIONS:
			return {...state, ...{isLoadingRegions: payload}};
		case REQUEST_AUTH:
			return {...state, ...{isAuthenticating: true}};
		case SET_REGIONS:
			return {...state, ...{regions: payload}};
		default:
			return state;
	}
};
