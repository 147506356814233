import {select, put, takeLatest} from 'redux-saga/effects';
import axios from "axios";

import {
	REQUEST_REGIONS,
	iRegion,
	iAction,
	setRegions,
	setLoadingRegions
} from '@/state/reducers/digitalocean';
import {iState} from "@/state";
import {apiUrl} from "@/api";

const regionCtyMap: any = {
	"nyc1": "US",
	"nyc2": "US",
	"nyc3": "US",
	"sfo1": "US",
	"sfo2": "US",
	"sfo3": "US",
	"ams2": "NL",
	"ams3": "NL",
	"lon1": "GB",
	"fra1": "DE",
	"blr1": "IN",
	"tor1": "CA",
};

function* __load_regions(action: iAction) {
	const state: iState = yield select();

	yield put(setLoadingRegions(true));

	const response = yield axios.get(apiUrl("digitalocean/regions"));
	const regionsFormatted = response.data.regions.map((r: iRegion) => {
		r.country = regionCtyMap[r.slug] || "US";
		return r;
	});

	yield put(setLoadingRegions(false));
	yield put(setRegions(regionsFormatted));
}

export function* loadRegionsSaga(storeAPI: any) {
	yield takeLatest(REQUEST_REGIONS, __load_regions);
}
