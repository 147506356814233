import {all} from 'redux-saga/effects';

import {
	loadRegionsSaga as loadDigitaloceanRegionsSaga
} from './digitalocean';

import {providerChangeSaga, persistSaga} from "@/state/saga/form";
import {requestSaga as requestServerSaga} from "@/state/saga/server";

export default function* rootSaga(storeAPI: any) {
	yield all([
		providerChangeSaga(storeAPI),
		loadDigitaloceanRegionsSaga(storeAPI),
		persistSaga(storeAPI),
		requestServerSaga(storeAPI),
	]);
}
