// @ts-ignore
import watch from 'redux-watch';


export default (store: any) => {
	let authTokenWatcher = watch(store.getState, 'digitalocean.authToken');

	store.subscribe(authTokenWatcher((newVal: any, oldVal: any, objectPath: any) => {
		if (newVal) {

		}
	}));
};
