import {select, call, put, takeLatest} from 'redux-saga/effects';
import axios from "axios";
import {navigate} from "gatsby";
import {omit} from "underscore";

import {SET_PROVIDER, PERSIST_REQUEST, persistError} from "@/state/reducers/form";

import {
	iAction,
	requestRegions, setRegions
} from '@/state/reducers/digitalocean';
import {iState} from "@/state";
import {apiUrl} from "@/api";

function* __load_regions(action: iAction) {
	const state: iState = yield select();
	switch (state.form.provider) {
		case "digitalocean":
			yield put(requestRegions());
			break;
		default:
			console.warn("can't load regions for unknown provider");
			yield put(setRegions([]));
	}
}

function* __persist(action: iAction) {
	const state: iState = yield select();
	const f = state.form;
	const docean = state.digitalocean;
	const authToken = f.provider == "digitalocean" ? docean.authToken?.token : "";

	if (!authToken) {
		console.error(`no auth token to attach`);
		yield put(persistError());
	} else {
		try {
			const response = yield axios.post(apiUrl("servers"), JSON.stringify(
				Object.assign({}, {authToken}, omit(f, "errors", "persistError")))
			);

			if (response.data.id !== "") {
				navigate("/payment/?s=" + response.data.id);
			}
		} catch (e) {
			console.error(e);
			yield put(persistError());
		}
	}
}

export function* providerChangeSaga(storeAPI: any) {
	yield takeLatest(SET_PROVIDER, __load_regions);
}

export function* persistSaga(storeAPI: any) {
	yield takeLatest(PERSIST_REQUEST, __persist);
}
