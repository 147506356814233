import wrapWithProvider from './src/wrappers'
import ReactDOM from 'react-dom';

import './site.css';
import 'react-redux-notify/dist/ReactReduxNotify.css';

require('typeface-montserrat');

const loadableReady = require('@loadable/component').loadableReady;

export const wrapRootElement = wrapWithProvider

export const replaceHydrateFunction = () => {
	return (element, container, callback) => {
		loadableReady(() => {
			ReactDOM.render(element, container, callback);
		});
	};
};
