import {createStore, compose, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {PERSIST, PURGE, REHYDRATE} from 'redux-persist/es/constants';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createStateSyncMiddleware, initMessageListener} from 'redux-state-sync';

import rootReducer from '@/state/reducers/index';
import rootSaga from '@/state/saga/index';
import watchers from '@/state/watchers';

const persistConfig = {
	key      : 'nologs-vpn-' + process.env.NODE_ENV,
	storage,
	blacklist: ['braintree']
};
const isServer = typeof window === "undefined";
const isProd = process.env.NODE_ENV === 'production';

declare global {
	interface Window {
		gtag: any;
		Paddle: any;
		devToolsExtension: any;
		__REDUX_DEVTOOLS_EXTENSION__: any;
	}
}

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export default () => {
	if (!isServer) {
		const stateSyncMiddleware = createStateSyncMiddleware(Object.assign({}, {
			blacklist: [...persistConfig.blacklist, PERSIST, PURGE, REHYDRATE]
		}));
		// @ts-ignore
		middlewares.push(stateSyncMiddleware);
	}

	let middleware = applyMiddleware(...middlewares);

	if (!isProd && !isServer) {
		const devtools = window.devToolsExtension
			? window.__REDUX_DEVTOOLS_EXTENSION__ &&
			window.__REDUX_DEVTOOLS_EXTENSION__()
			: (f: any) => f;
		middleware = compose(middleware, devtools);
	}

	const persistedReducer = persistReducer(persistConfig, rootReducer);
	const store = createStore(persistedReducer, middleware);
	const persistor = persistStore(store);

	const storeAPI = {
		subscribe: store.subscribe,
		dispatch : store.dispatch,
		getState : store.getState,
	};

	sagaMiddleware.run(rootSaga, storeAPI);

	watchers(store);

	if (!isServer) {
		initMessageListener(store);
	}

	return {store, persistor};
};
