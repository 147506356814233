import * as React from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import createStore from '@/state/create-store';

export default ({element}: any) => {
	const store = createStore();

	return <Provider store={store.store}>
		<PersistGate loading={null} persistor={store.persistor}>
			<div className="root overflow-hidden">{element}</div>
		</PersistGate>
	</Provider>;
};


//	no `PersistGate` on ssr because our html will not be produced
const wrapSsrWithProvider = ({element}: any) => {
	const store = createStore();

	return <Provider store={store.store}>
		<div className="root overflow-hidden">{element}</div>
	</Provider>;
};

export const wrapRoot = ({element}: any) => {
	return wrapSsrWithProvider(element);
};
