export const RESET = 'FORM/RESET';
export const SET_NAME = 'FORM/SET_NAME';
export const SET_REGION = 'FORM/SET_REGION';
export const SET_PROVIDER = 'FORM/SET_PROVIDER';
export const SET_PROTOCOL = 'FORM/SET_PROTOCOL';
export const SET_MTU = 'FORM/SET_MTU';
export const SET_PORT = 'FORM/SET_PORT';
export const SET_DNS_SERVERS = 'FORM/SET_DNS_SERVERS';
export const SET_MASK_IP = 'FORM/SET_MASK_IP';
export const SET_SERVICE = 'FORM/SET_SERVICE';
export const SET_EMAIL = 'FORM/SET_EMAIL';
export const ADD_ERROR = 'FORM/ADD_ERROR';
export const CLEAR_ERRORS = 'FORM/CLEAR_ERRORS';
export const PERSIST_REQUEST = 'FORM/PERSIST_REQUEST';
export const PERSIST_ERROR = 'FORM/PERSIST_ERROR';


interface iError {
	field: string;
	error: string;
}

export interface iAction {
	type: string;
	payload?: string | boolean | string[] | iError;
}


export interface iState {
	service: string;
	email: string;
	name: string;
	provider: string;
	region?: string;
	protocol: string;
	port: string;
	mtu?: number;
	dnsServers: string[];
	maskIp: boolean;
	persistError?: boolean;
	errors?: {
		service: string;
		email: string;
		name: string;
		mtu: string;
		provider: string;
		region?: string;
		protocol: string;
		port: string;
		dnsServers: string[];
		maskIp: boolean;
	}
}

const initialState: iState = {
	name      : "nologs-vpn",
	email     : "",
	service   : "openvpn",
	provider  : "digitalocean",
	protocol  : "udp",
	dnsServers: ["1.1.1.1", "1.0.0.1"],
	port      : "58281",
	maskIp    : true
};

export const reset = (): iAction => ({type: RESET});
export const persist = (): iAction => ({type: PERSIST_REQUEST});
export const persistError = (): iAction => ({type: PERSIST_ERROR});
export const addError = (field: string, error: string): iAction => ({type: ADD_ERROR, payload: {field, error}});
export const clearErrors = (): iAction => ({type: CLEAR_ERRORS});
export const setEmail = (email: string): iAction => ({type: SET_EMAIL, payload: email});
export const setService = (service: string): iAction => ({type: SET_SERVICE, payload: service});
export const setName = (name: string): iAction => ({type: SET_NAME, payload: name});
export const setRegion = (region: string): iAction => ({type: SET_REGION, payload: region});
export const setProvider = (provider: string): iAction => ({type: SET_PROVIDER, payload: provider});
export const setProtocol = (protocol: string): iAction => ({type: SET_PROTOCOL, payload: protocol});
export const setPort = (port: string): iAction => ({type: SET_PORT, payload: port});
export const setMtu = (mtu: string): iAction => ({type: SET_MTU, payload: mtu});
export const setDnsServers = (dnsServers: string[]): iAction => ({type: SET_DNS_SERVERS, payload: dnsServers});
export const setMaskIp = (maskIt?: boolean): iAction => ({type: SET_MASK_IP, payload: maskIt});

export default (state = initialState, action: iAction) => {
	const {type, payload} = action;
	switch (type) {
		case RESET:
			return initialState;
		case SET_EMAIL:
			return {...state, ...{email: payload}};
		case SET_NAME:
			return {...state, ...{name: payload}};
		case SET_SERVICE:
			return {...state, ...{service: payload}};
		case SET_REGION:
			return {...state, ...{region: payload}};
		case SET_PROVIDER:
			return {...state, ...{provider: payload}};
		case SET_PROTOCOL:
			return {...state, ...{protocol: payload}};
		case SET_PORT:
			return {...state, ...{port: payload}};
		case SET_MTU:
			return {...state, ...{mtu: payload}};
		case SET_DNS_SERVERS:
			return {...state, ...{dnsServers: payload}};
		case SET_MASK_IP:
			return {...state, ...{maskIp: payload}};
		case PERSIST_REQUEST:
			return {...state, ...{persistError: false}};
		case PERSIST_ERROR:
			return {...state, ...{persistError: true}};
		case ADD_ERROR:
			const err = <iError>payload;
			return {
				...state, ...{
					errors: {
						...state.errors, ...{
							[err.field]: err.error
						}
					}
				}
			};
		case CLEAR_ERRORS:
			return {...state, ...{errors: undefined}};

		default:
			return state;
	}
};
