import {select, call, delay, put, takeLatest} from 'redux-saga/effects';
import axios from "axios";

import {REQUEST, set, iAction, iStatus, iServer, request} from "@/state/reducers/server";

import {iState} from "@/state";
import {apiUrl} from "@/api";

function* __request(action: iAction) {
	const state: iState = yield select();
	const response = yield axios.get(apiUrl("servers/status?id=" + action.payload));
	yield put(set(response.data));

	const s: iServer = response.data;

	// @TODO: this code runs even if we havigated away from the success page
	// @TODO: move it to component mount effect
	if (s.serverStatus != iStatus.FINISHED && (!s.updatedAt || new Date().getTime() - s.updatedAt < 600000)) {
		yield delay(5000);
		yield put(request(`${action.payload}`));
	}
}

export function* requestSaga(storeAPI: any) {
	yield takeLatest(REQUEST, __request);
}
